import getCountries from "./getCountries";
import getStates from "./getStates";
import useDebounce from "./useDebounce";
import useWindowDimensions from "./useWindowDimensions";
import downloadFile from "./downloadFile";
import { convertToCurrentTimeZone, getCurrentTimeZone, getDiffernceInDays, getTimeIn12HrFormat } from './time';
import { Format, NACCDataType, NACCUploadType } from "../models/Contract";
import { getHtmlForNACC, getHtmlForSpins } from "./reportTemplateHelper";

const emailIsValid = (email: unknown): boolean => {
    if (typeof email !== 'string') {
        return false;
    } else {
        const exp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
        return exp.test(email);
    }
};

const passwordIsValid = (password: unknown): boolean => {
    if (typeof password !== 'string') {
        return false;
    } else {
        const exp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        return exp.test(password);
    }
};

const phoneNumberIsValid = (value: unknown): boolean => {
    if (typeof value !== 'string') {
        return false;
    } else {
        const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (regex.test(value) && value.length === 10) {
            return true;
        } else {
            return false;
        }
    }
};

const passwordAreEqual = (password: unknown, confirmPassword: unknown): boolean => {
    if (typeof password !== 'string' || typeof confirmPassword !== 'string') {
        return false;
    } else {
        return confirmPassword === password;
    }
};

const zipCodeIsValid = (zip: unknown): boolean => {
    if (typeof zip !== 'string') {
        return false;
    } else {
        return zip.length === 5;
    }
};

const ageIsValid = (dob: unknown): boolean => {
    if (typeof dob !== 'string') {
        return false;
    } else {
        const parts = dob.split('-');
        if (parts.length < 3) {
            return false;
        }
        const dtDOB = new Date(parts[1] + '/' + parts[2] + '/' + parts[0]);
        const dtCurrent = new Date();
        if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 18) {
            return false;
        }
        if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 18) {
            //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
            if (dtCurrent.getMonth() < dtDOB.getMonth()) {
                return false;
            }
            if (dtCurrent.getMonth() == dtDOB.getMonth()) {
                //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
                if (dtCurrent.getDate() < dtDOB.getDate()) {
                    return false;
                }
            }
        }
        return true;
    }
};

const urlIsValid = (url: unknown): boolean => {
    if (typeof url !== 'string') {
        return false;
    }

    try {
        // The URL constructor throws an error if the URL is invalid
        new URL(url);
        return true;
    } catch {
        return false;
    }
};


const formatPhoneNumber = (phoneNumber: unknown): string => {
    if (typeof phoneNumber !== 'string') {
        return 'Invalid number';
    } else {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return 'Invalid number';
    }
};

const formatLocation = (
    addressline1 = '',
    addressline2 = '',
    city = '',
    state = '',
    country = '',
    zip = '',
) => {
    let resString = '';

    if(addressline1?.length > 0) resString += `${addressline1}, `
    if(addressline2?.length > 0) resString += `${addressline2}, `
    if(city?.length > 0) resString += `${city}, `
    if(state?.length > 0) resString += `${state}, `
    if(country?.length > 0) resString += `${country}`
    if(zip?.length > 0) resString += `, ${zip}`

    return resString;
};

const formatDate = (date: string | Date):string => {
    return new Date(date).toLocaleDateString('en-US');
}

const FormatDateInputValue = (date: string | Date | null | undefined):string | undefined=> {
    if(!date || date.toString().length===0) return undefined; 
    date = new Date(date);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());    
    date =  date.toISOString().substring(0, 10);
    return date;
}
const setCookie=(cname: string, expireAfterMonths: number, value: string, path?:string) => {
    const expiryMonth = new Date(new Date().getMonth()+expireAfterMonths).toUTCString();
    document.cookie = `${cname}=${value}; expires=${expiryMonth}; path=${path}`;
}

const getCookie=(cname: string):string=> {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";

}

const getUserRoles = (): { value: string; label: string }[] => {
    return [
        {
            label: 'Admin',
            value: 'Admin',
        },
        {
            label: 'Manager',
            value: 'Manager',
        },
        {
            label: 'Synner',
            value: 'Synner',
        },        
        {
            label: 'Trainee',
            value: 'Trainee',
        },
    ];
};

const endDateIsValid = (startDate: unknown, endDate: unknown): boolean => {
    if (typeof startDate !== 'string' || typeof endDate !== 'string') {
        return false;
    } else {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);

        return newEndDate >= newStartDate;
    }
};

const amountIsValid = (amount: unknown): boolean => {
    if (typeof amount !== 'string' && typeof amount !== 'number') {
        return false;
    } else {
        const regex = new RegExp(/^\d+(?:[.,]\d+)*$/);
        if (regex.test(amount.toString())) {
            return true;
        } else {
            return false;
        }
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getListFromEnum(enumvalues: string | any[]): {label: string, value: string | number}[]{
    const resp: { label: string; value: string| number; }[] =[];
    const half_length = Math.ceil(enumvalues.length / 2);
    const labels = enumvalues.slice(0,half_length) as string[];
    const values = enumvalues.slice(half_length, enumvalues.length);
    labels.forEach((el, index)=> {
        resp.push({
            label: el,
            value: values[index]
        })
    });

    resp.sort((a, b) => a.label.localeCompare(b.label));
    return resp;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFormatName(value: any) {
    for (const name in Format) {
        if (Format[name] === value) {
            return name;
        }
    }
    return undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNACCAddGenre(value: any, uploadType: NACCUploadType) {
    if(uploadType== NACCUploadType.Spin){
        if(value == 7){
            return "Heavy";
        }
    }
    for (const name in NACCDataType) {

        if (NACCDataType[name] === value) {
            return name;
        }
    }
    return undefined;
}

function checkAdds (genre: NACCDataType |undefined) {
    if(genre == NACCDataType["Heavy Adds"] || genre == NACCDataType["Top Adds"] || genre == NACCDataType["Electronic Adds"] || genre == NACCDataType["Hip-Hop Adds"]){
        return true;
    }
    else{
        return false;
    }
}

function getSelectedGridData (obj: {[id: string]: boolean | number[];}){
    const result = Object.keys(obj).filter((key) => {
        return obj[key] === true;
      });
    
      return result;
}

function getFormatList(): Array<Format> {
    const res: Array<Format> = [];
    res.push(Format.AAA);
    res.push(Format.Alternative);
    res.push(Format.Americana);
    res.push(Format.College);
    res.push(Format.Electronic);
    res.push(Format["Hip-Hop"]);
    res.push(Format.Metal);
    res.push(Format.Rock);
    res.push(Format.Specialty);
    res.push(Format.Other);
    return res;
}

export {
    getHtmlForNACC,
    getHtmlForSpins,
    emailIsValid,
    passwordAreEqual,
    phoneNumberIsValid,
    zipCodeIsValid,
    passwordIsValid,
    ageIsValid,
    urlIsValid,
    getStates,
    getCountries,
    endDateIsValid,
    amountIsValid,
    formatPhoneNumber,
    getUserRoles,
    formatLocation,
    formatDate,
    getFormatName,
    getCookie,
    setCookie,
    useDebounce,
    useWindowDimensions,
    downloadFile,
    FormatDateInputValue,
    getListFromEnum,
    getCurrentTimeZone,
    getTimeIn12HrFormat,
    convertToCurrentTimeZone,
    getDiffernceInDays,
    checkAdds,
    getSelectedGridData,
    getNACCAddGenre,
    getFormatList
};
